@media (max-width: 1052px) {


  .d-flex.docs-highlight {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .divCabecalho {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

}

@media (min-width: 768px) {
  html:not([dir='rtl']) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(0 * var(--cui-sidebar-width));
  }
}



textarea#mensagem {
  font-size: 15px;
  color: var(--cor-cinza-b);
  width: 100%;
  height: 80px;
  border-radius: 15px;
  border: solid 1px var(--cor-cinza-b);
  outline: none;
  padding: 10px 20px 0 20px;
  margin-top: 10px;
}


.titulo-cadastro-usuario {
  font-size: 15px;
  color: var(--cor-primary);
  font-weight: bold;
}

.titulo-info-empresa {
  font-size: 18px;
  color: var(--cor-secondary);
  font-weight: bold;
}

.cabecalho-info-empresa {
  padding: 15px;
  border-bottom: 1px solid var(--cor-cinza-a);
}

.cabecalho-cadastro-usuario {
  padding: 15px;
  border-bottom: 1px solid var(--cor-cinza-a);
}

input#text-area {
  flex: 1 1 100px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  color: var(--cor-secondary);
  outline: none;
  font-size: 14px;
  margin-bottom: 10px;
}

#select-cadastro-usuario {
  width: 100%;
  border-radius: 15px;
  color: #80808099;
  outline: none;
  resize: none;
  font-size: 14px;
}

.select-form-usuario {
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}

.select-cadastro-usuario {
  width: 100%;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--cor-cinza-b);
}

select#cadastro-usuario {
  flex: 1 1 100px;
  border: solid 1px var(--cor-cinza-b);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-cinza-b);
  outline: none;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  margin-top: 5px;
}

.label-form-usuario {
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
}

.button-cadastrar-setor {
  flex: 1 1 100px;
  margin-top: 1.5%;
  margin-bottom: 1%;
  border: none;
  width: 100%;
  align-items: center;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-primary);
  cursor: pointer;
  outline: none;
  background-color: var(--cor-cinza-c);
}

.th-detalhes {
  align-items: end;
  flex: end;
  justify-content: end;
  text-align: right;
}



input.button-cadastrar-usuario {
  padding: 10px 25px 10px 25px;
  border: none;
  border-radius: 15px;
  background-color: var(--cor-cinza-c);
  cursor: pointer;
  color: var(--cor-primary);
  width: 100%;
  margin-top: 20px;
}

.label-form {
  margin-bottom: 10px;
}

textarea#info-empresa {
  flex: 1 1 100px;
  border: solid 1px var(--cor-secondary);
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 10px;
  color: var(--cor-secondary);
  outline: none;
  resize: none;
  font-size: 14px;
  overflow: hidden;
}

.tabList{
  display: flex;
  overflow-x: auto;
}


.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--cui-modal-header-padding);
}

.modal {
  --cui-modal-header-border-color: none;
}
