.modal-content {
    border-radius: 10px;
    background-color: #f9f9f9;
}

.modal-white {
    border-radius: 10px;
    background-color: #fff;
}

.modal-backdrop {
    --cui-backdrop-zindex: 1050;
    --cui-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--cui-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: #0000158f;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--cui-modal-color);
    pointer-events: auto;
    background-color: var(--cui-modal-bg);
    background-clip: padding-box;
    border: var(--cui-modal-border-width) solid var(--cui-modal-border-color);
    border-radius: var(--cui-modal-border-radius);
    outline: 0;
    box-shadow: 0 0 0 0 #ffffff00;
}


.title-modal {
    font-size: 27px;
    color: var(--cor-primary);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem;
    border: none;
}

@media (max-width: 420px) {
    .modal-body {
        padding: 2rem 1rem;
    }
}

.line-blue-modal {
    background-color: var(--cor-primary);
    width: 100px;
    height: 2px;
    margin: 20px 0px;
}

@media (max-width: 600px) {
    .modal.show .modal-dialog {
        transform: none;
        padding: 10px;
        position: relative;
    }
    .descriptiveModal form{
      grid-template-columns: 100% !important;
    }
}

html:not([dir=rtl]) .modal {
    z-index: 100000;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(3 px);
}

.title-modal-delete {
    color: var(--cor-vermelho);
    font-size: 18px;
    font-weight: 600;
    font-size: 24px;

}

.document-flex-driver {
    display: flex;
    justify-content: space-between;
}


.wigth-250 {
    width: 250px;
}

.cont-line-a {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #e9e9e9;
    margin-bottom: 20px;
    margin-top: 20px;
}

.descriptiveModal form{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

@media screen and (max-width: 1200px){
  .descriptiveModal form{
    grid-template-columns: 50% 50%;
  }
}
