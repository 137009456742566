#redefinePassword {
  background-image: url('../../assets/images/login/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  height: 100vh;
}

.redefinePasswordTitle {
    padding-bottom: 0px;
    display: grid !important;
    justify-self: center !important;
    text-align: center;
}

.redefinePasswordImg {
    margin-bottom: 30px;
    justify-self: center;
}

.redefinePass{
  background-color: rgba(255, 255, 255, 0) !important;
}
