.viewModal{
  padding: 20px;
  display: grid;

  .title-modal{
    padding: 2rem !important;
  }
  h2{
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
  }
  h3{
    font-size: 16px !important;
    font-weight: 500;
    color: rgb(168, 168, 168);
    padding-bottom: 15px;
  }
  h4{
    font-size: 14px;
    font-weight: 700;
    color: var(--cor-primary);
    margin-bottom: 0px;
  }
  p{
    color: rgb(109, 109, 109);
    padding-bottom: 5px;
    font-size: 16px;
  }
  .line-blue-modal{
    width: 95%;
    justify-self: center;
  }
  .line-gray-modal{
    height: 1px;
    margin: 10px 0px 30px;
    width: 95%;
    justify-self: center;
    background-color: rgb(212, 212, 212);
  }
  .buttons{
    gap: 5px;

    .buttonLeft{
      background-color: rgb(248, 248, 248);
      border: 1px solid var(--cor-primary);
      color: var(--cor-primary);
    }
    .buttonRight{
      border: 1px solid var(--cor-primary);
    }
  }
  .carousel{
    max-width: 1000px;
    .swiper-wrapper{
      .swiper-slide{
        display: grid;
        justify-content: center;
        width: fit-content;

        img{
          width: 400px;
        }
      }
    }
  }
}
