/* ------------------------- HEADER ------------------------- */
#header {
    border: none;
}
.container{
    max-width: 95% !important;
}
.header{
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none !important;
}

/* TITULO ESQUERDO DO HEADER */
.text-color-primary {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    color: var(--color-primary);
}

/* DIVISOR DA HEADER */
.cont-line-h {
    content: "";
    display: block;
    height: 40px;
    width: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 20px;
    /* margin-right: px; */
    background-color: var(--color-primary);
}

/* NOME DO HEADER */
#profileName {
    font-size: 15px;
    color: var(--color-primary);
    white-space: nowrap;
}
