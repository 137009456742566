.totals{
  display: flex;
  justify-content: space-between;
  padding: 0px 50px 0px 0px;

  p{
    font-size: 18px;
    color: var(--cor-primary);
  }

  .success{
    color: rgb(22, 190, 0);
  }
  .fails{
    color: rgb(134, 0, 0);
  }
}

.bad-success{
  font-size: 18px;
  color: gray;
}

.modal-white{
  background-color: rgba(255, 255, 255, 0.322) !important;
}
