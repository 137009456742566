html:not([dir=rtl]) .form-select-lg {
    padding-left: 0.65rem;
    padding-top: 19px;
}

.form-select {
    border-radius: 5px;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(2.5rem + 2px);
    min-height: calc(3rem + 2px);
    width: 100%;
    line-height: 1.25;
}

.form-select {
    border: 1px solid var(--cor-secondary);
}

.form-floating>label {
    position: absolute;
    top: 0;
    width: 100%;
    font-size: 13px;
    padding: 0.65rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
    opacity: 3;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    font-size: 17px;
    color: #003391;
}

.form-control {
    border: 1px solid rgba(0, 0, 0, 0.219) !important;
    color: var(--cor-secondary);
    border-radius: 5px;
    font-size: 15px;
}

.form-check-input:checked {
    background-color: var(--cor-primary);
    border-color: var(--cui-form-check-input-checked-border-color, #c50e0e);
}

.form-check-input {
    flex: 1 1 15px;
}

.form-control {
    color: var(--cor-secondary);
    font-weight: 600;
}

.form-label {
    color: var(--cor-secondary);
    background-color: none;
}

.col-form-label {
    color: var(--cor-secondary);
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
    color: var(--cor-secondary);
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
    background: transparent;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    background-color: #ffffff00;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    height: 0.5em;
}

html:not([dir=rtl]) .form-select {
    padding: 1.375rem 0.25rem 0.375rem 0.75rem;
}


.padding-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.form-floating {
    position: relative;
    width: 100%;
}

.select-global {
    border-radius: 5px !important;
    border: 1px solid rgba(0, 0, 0, 0.219) !important;
}

.picker-input-group input {
    height: 50px;
}

.inputArquivo{
    line-height: 32px !important;
    height: 50px !important;
}

.buttons{
    display: flex;
    gap: 2px;
}

.btn1{
    background-color: #00276e;
}

.nav-tabs .nav-link.active{
    max-width: 40%;
}

.nav.nav-justified.nav-tabs{
    justify-content: space-between;
}

.btn.btn-primary{
    margin-bottom: 5px;
    background-color: var(--cor-azul-a);
    color: white;
    font-weight: 500;
    margin: 0px;
    height: 44px !important;
    align-self: center;
}

.modals{
    width: fit-content;
    display: flex;
    gap: 2px;
}
.modals button{
    margin: 0px !important;
    height: 44px;
    align-self: center;
}

.nav-underline, .nav-link{
    border-bottom: 0px !important;
}

.ctab{
    display: flex;
    align-items: center;
    justify-content: center;
}

.tourBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  color: white;
  background-color: #071A3D;
  cursor: pointer;
}

.nav-underline .nav-link.active{
    color: #00369b;
    font-weight: 700;
    border-bottom: 2px solid #00369b !important;
}
.nav-underline .nav-link{
    border-bottom: 2px solid #e6e6e6 !important;
}
.list-table{
  background-color: #ffffff00;
  transition: .5s ease;
}
.selected-row {
  background-color: #e3edff; /* Escolha a cor que você preferir */
}
.table> :not(caption)>*>*{
  background-color: #00266e00 !important;
}
.form-check-input{
  border-radius: 50px !important;
  width: 2em;
  height: 2em;
}
.form-check-input:checked{
  border-color: #00276e;
}

.tab-content{
    box-shadow: 0px 0px 0px !important;
    border: 0px !important;
}

.clearButton{
    background-color: #00276e;
    padding: 6px 12px;
    border-radius: 5px;
    align-self: center;
    color: white;
    max-height: 44px;
}

.lupa{
    margin: 0px 0px -3px 5px;
}

.form-label{
    color: var(--cor-azul-a);
}

@media (max-width: 1200px) {
    form {
        display: grid !important;
    }
    .form-select, .form-control{
      margin-bottom: 5px !important;
    }
}


