.title-tab {
    color: var(--cor-primary);
    font-weight: 700;
    background-color: #e9e9e9;
    border-radius: 5px;
    padding: 10px;
    display: flex;
}


.title-tab-disabled {
    color: rgb(91, 91, 91);
    background-color: #fff;
    padding: 10px;
}

.tab-number {
    color: #fff;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--cor-primary);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: block;
}

.title-tab-disabled .tab-number {
    color: #fff;
    background-color: rgb(91, 91, 91);
}

@media screen and (max-width: 1400px){
  .painel{
    display: grid !important;
    gap: 10px !important;
  }
}
