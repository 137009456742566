.content-tabela {
  width: 100%;
  border-radius: 10px;
  padding: 10px;

}

.float-left {
  float: left;
}

.content-tabela th {
  color: var(--cor-primary);
  font-weight: 700;
  font-size: 16px;
  justify-content: end;
}

.content-tabela tr td{
  font-size: 15px;
}

.content-tabela tr th {
  min-width: fit-content;
  width: fit-content;
}

.tools {
  text-align: end;
  margin: 0px 20px 0px 0px;
  gap: 15px;
}

.tools button {
  margin-left: 3px;
}

.table> :not(caption)>*>* {
  justify-content: end;
  margin-right: 20px;
}

.content-tabela tr {
  padding: 4px 6px;
}

.content-tabela td {
  color: #808080;
  font-size: 16px;
  height: 52px;
  padding: 8px;
}

.button-table-red {
  padding: 7px;
  max-width: 50px;
  background-color: #ff3939;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  transition: .5s ease;
}

.button-table-red:hover {
  background-color: #ff3939b2;
}

.button-table-yellow {
  padding: 7px;
  max-width: 50px;
  background-color: #f7ac00;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  transition: .5s ease;
}

.button-table-yellow:hover {
  background-color: #f7ad00b2;
}

.button-table-blue {
  padding: 7px;
  max-width: 95px;
  background-color: #0052cc;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  transition: .5s ease;
}

.button-table-blue:hover {
  background-color: #0052ccb2;
}

.button-table-green {
  padding: 7px;
  max-width: 95px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  transition: .5s ease;
}

.button-table-green:hover {
  background-color: #00cc33b2;
}

.button-table-green.disabled {
  background-color: #d3d3d3; /* Cor de fundo cinza */
  cursor: not-allowed; /* Mostra que o botão não pode ser clicado */
  opacity: 0.6; /* Torna o botão parcialmente transparente */
}

.btnView img {
  vertical-align: -0.125rem;
  height: 1.25rem;
  width: 1.25rem;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.form-control {
  height: 33px;
}

.flex-table {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.list-table {
  border-top: 2px solid #c2c2c299;
  padding: 6px;
  margin-top: 4px;
}

select.select-table {
  width: 110px;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid var(--cor-secondary);
  border-radius: 10px;
  font-size: 15px;
  outline: none;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--cui-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
}

.table-buttons {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .content-tabela td {
    color: #808080 var(--cor-cinza-b);
    font-size: 12px;
    padding: 8px;
  }

  .content-tabela th {
    color: var(--cor-primary);
    font-size: 14px;
    font-weight: 700;
  }
}

@media (max-width: 800px) {
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --cui-table-accent-bg: #ffffff00;
    color: var(--cui-table-striped-color);
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-width: 0;
  }

  .table> :not(caption)>*>* {
    padding: 0.5rem 1rem;
  }

  .table-buttons {
    flex-direction: column-reverse;
    gap: 10px;
  }
}


@media screen and (max-width: 1550px){
  .originTable, .destinyTable , .parkingTable{
    display: none;
  }
}

@media screen and (max-width: 1250px){
  .originTable, .destinyTable , .parkingTable{
    display: none;
  }

  th{
    font-size: 14px !important;
  }

  td{
    font-size: 12px !important;
  }

  .btnView img {
    width: 1.1rem;
    height: 1.1rem;
  }

  .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg{
    width: 1.1rem;
    height: 1.1rem;
  }

  .button-table-green, .button-table-blue, .button-table-red, .button-table-yellow{
    padding: 6px;
  }

  .text-gray{
    font-size: 12px !important;
  }
  a.page-link{
    padding: 3px 6px !important;
    font-size: 12px;
  }

  span{
    font-size: 12px !important;
    padding: 3px 6px !important;
  }
}

@media screen and (max-width: 1150px){
  .automakerTable, .modelTable , .shipTable, .conditionTable, .classificationTable{
    display: none;
  }
}

@media screen and (max-width: 850px){
  .severityTable, .colorTable{
    display: none;
  }
}

@media screen and (max-width: 768px){
  .automakerTable, .modelTable , .shipTable, .button-table-yellow, .button-table-red, .eventTable, .automakerTable{
    display: none;

  }
}

@media screen and (max-width: 700px){
  .areaTable, .subareaTable, .damageTable{
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .checkpointTable{
    display: none;
  }
  .cont-filtro{
    padding: 5px !important;
  }
  .table> :not(caption)>*>*{
    padding: 8px 8px 4px !important;
  }
}
