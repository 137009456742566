.viewMovementModal{
  .modal-dialog{
    .modal-body{
      .row{
        display: grid;
        grid-template-columns: 33% 33% 33%;
      }
    }
  }
}

@media screen and (max-width: 992px){
  .viewMovementModal{
    .modal-dialog{
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 768px){
  .viewMovementModal{
    .modal-dialog{
      max-width: 95%;

      .modal-body{
        padding: 10px;
      }
      .cont-filtro{
        padding: 0px;
        .content-tabela{
          padding: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px){
  .viewMovementModal{
    .modal-dialog{
      max-width: 100%;

      .modal-body{
        padding: 10px;

        .row{
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }
      .cont-filtro{
        padding: 0px;
        .content-tabela{
          padding: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px){
  .viewMovementModal{
    .modal-dialog{
      h2{
        font-size: 20px;
      }

      .modal-header{
        padding: 1rem .5rem .5rem !important;
      }

      .line-blue-modal{
        height: 1px;
      }

      p{
        margin-bottom: 5px;
      }
    }
  }
}
