#login {
    overflow-x: hidden;
    background-image: url('../../assets/images/login/bg.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
}

.square{
    position: absolute;
    justify-self: center;
    align-self: center;
    z-index: 100;
}
.square:hover{
    opacity: 100%;
    animation: rota .8s ease forwards;
}

.align-message {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
}

.loginContent {
    height: 100%;
    display: grid;
    grid-template-rows: 20%;
}

.loginContentImg {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    opacity: 0%;
    animation: slideLogo 1.5s ease forwards;
    padding: 20px 50px;
    height: fit-content;
}

.loginContentText {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    animation: slideLogo 2s 0.5s ease forwards;
    opacity: 0%;
    padding: 100px 0px 0px 15%;
    height: 100%;
    align-items: center;
}

.loginContentText h2 {
    height: fit-content;
    width: fit-content;
    color: white;
    font-size: 38px;
}

.loginContentText h3 {
    height: fit-content;
    width: fit-content;
    color: rgba(255, 255, 255, 0.815);
    font-size: 32px;
}

.rights {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    opacity: 0%;
    animation: slideRights 1s 3s ease forwards;
    justify-self: center;
    margin: auto auto 10px auto;
}

.rights a {
    text-decoration: dashed;
    color: white;
    font-weight: 800;
    font-size: 18px;
}

.form {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    width: auto;
    animation: slideForm 1.5s 1.5s ease forwards;
    opacity: 0%;
    margin: auto 0px auto auto;
    height: 100vh;
    grid-template-rows: max-content auto auto;
    display: grid;
    padding: 120px 5% 0% 5%;
    -webkit-backdrop-filter: blur(10.5px);
    backdrop-filter: blur(5.5px);
    background-color: var(--cor-azul-a);
}

.formTitle {
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: center;
    width: 60%;
    height: fit-content;
}

.formTitle h1 {
    color: white;
    font-size: 40px;
    font-weight: 500;
}

.formTitle p {
    padding: 20px 0px;
    font-weight: 400;
    font-size: 14px;
    color: rgb(194, 194, 194);
}


.formBody {
    align-self: center;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 60%;
    display: grid;
    justify-self: center;
    align-items: center;
    gap: 20px;
    height: fit-content;
}

.formItem {
    display: grid;
    height: fit-content;
}

.formItem input {
    background-color: #00215F;
    border-bottom: 1px solid #2a4880;
    border-radius: 0px;
    height: 45px;
    padding: 0px 5px;
    font-size: 17px;
    font-weight: 400;
    color: rgb(206, 206, 206);
}

input:focus::placeholder {
    transform: translateX(-30px);
    opacity: 0;
}

::placeholder {
    transition: 0.2s ease;
    font-weight: 400;
    font-size: 16px;
    font-weight: 500;
    color: rgb(182, 182, 182);
}

.itemPass {
    display: grid;
    grid-template-areas: 'itemPass';
}

.pass {
    width: 100%;
    grid-area: itemPass;
}

.checkbox {
    opacity: 50%;
    display: grid;
    justify-self: center;
    align-self: center;
    margin-right: 30px;
    justify-self: right;
    grid-area: itemPass;
    filter: brightness(100);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAGpSURBVHja7JdNK0VRFIbf6+NK3YgRE5IQE4ViphgqytdI+QVM5BcYSpkoZaLEwMDAgJRM+AEM7sCAwoCYKFGEx8A62W33nuO6dCfnrdU5e6291nr3WWvvzk4AKiSKVGDEBGICMYGYQCYCjZJ6/yFXhaSSb1rAlRRwzyemPVs+MgA8A2kg6dr8ieUOgUeg5Q+S1wF3FvMVqA4jIGCKL6SByjySV1uMAPP+nKgmbJO0Iyn1i5qnJG1bjAB3UT0waJ/JxwlQn8PKG8zHxxswmq0E9Vb3ALPAsTO+t8YsCUmcBGaAB8fv3PzebPwEtGYi0OM4zZmuCtj1VnENLADDQAfQaatadJotwCFQa7GmHX1fkDfh/RGNSyqVtCEpMNRIujR9LniX1CzpzNENSSqTtJmtBzLJksP8ALghO26BPWe8GhU/Knkz8GLBLu2cKAa67JMHWAG6gVKTU6fp2vMhsOwkmfRsQ45twrONOLa1sBxR58CFPY8kreVQ/y1J+/Z+FTrzBz3Q5J/fGXZNf5Yt2QoUhcVP5HkvGJOUlLT+2wCJ+GISE4gJFJrAxwA6DTlYuh6SPwAAAABJRU5ErkJggg==');
    background-size: cover;
    width: fit-content;
    height: fit-content;
}

.checkboxTrue {
    opacity: 50%;
    display: grid;
    justify-self: center;
    align-self: center;
    margin-right: 30px;
    justify-self: right;
    grid-area: itemPass;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAACxAAAAsQHGLUmNAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAALOSURBVHja7JdLSFZREMf/ny/KyCASUyJ7gLXJSqSSFlFo1Cp6UG2EIgoCW9SmcBto6wJ7bCKsRUoPowfSA6R1tmiVrXoRnxlmavmg79fmXBgO595rSrjIgcM998x/5syZO2dmbgbQbFKeZpnmDCiYgewCSYvcfEjS6L82oELSQUn1kmoklXv8L5J6JT2V1OHe0wlIG+uBTmCSqdMk0AFUp+nPJFzDEkktkk56sZKV9ELSe0mfHa9MUqWknZJKDTYnqU1Ss6Thv/FANdBnTvQbaAe2A/kJJ8oHtgE3gZyRfwusC8mElNQDw0b4JVAbwBUBa9woCvBrgR6j54fTnWhAPTDmBHLAeSDjYVa7E44a5SPOQ6s8bB7QYnBjvhEWXOOsjICNgVPtAIYSgu+7+0y+XKM52BCwwTdgCfDJnPxQQMlKt0FEd4ATbtw164NAZUD+sImLD8Bia0CXUXAuJsBuGMyRAP+o4V+P0dFsMPciA/abxdsxgoXm83Ql3IL7xs0FMZhOs98+Ae/cS9Z9ipBQpRE6lWDAMYNbHoMpdXsB9OVJmnApoVBSUVzCNPP8KRa3uAxX6IYkTQjYbax+GLh2cu4cdJjuBA90O8y3mISVAR6Z/XZFjFtmsTVG+VWDOR3gnzH8thgdrQbTbm9BiYkFgOMB4Qqg32CeAU0uJp6b9SxQnhIffcBCPxGtBQZMNWsKKNnsGeFTFtgUkDtlqulXoCouFde5tBrRZXcFLWYpcMkzpB+4CJQFYqfNS9l1acVoi/EEwOtQEXGjLLBpNBqAN0bPgPNgajWMCk6v597HwF6gOOEWFAMHgCee7KtAoUptSOa5RuKslx9+SuqR9NG1XTlJy1xDstX1ihGNS7ogqdXNp9WSrQCueeU3jUaAKzFFacoeCLVoeyQ1SNooqcp4ZlxSn2lKH8S2YB5lZvhrNt89f01XQWbu3/C/N+DPACJ9XvKBLjr+AAAAAElFTkSuQmCC');
    background-size: cover;
    width: fit-content;
    height: fit-content;
}

#check input {
    cursor: pointer;
    opacity: 0 !important;
    width: 25px;
    height: 25px;
}

.formBtn {
    margin-top: 30px;
    justify-self: center;
    height: 50px;
    width: 100%;
    box-shadow: -10px 15px 15px rgba(0, 0, 0, 0.322);
    background-color: #003088;
    border-radius: 10px;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: rgb(211, 211, 211);
    transition: 0.3s ease;
}

.formBtn:hover {
    color: rgb(255, 255, 255);
}

.problems {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.problems p {
    margin: 0px;
    color: white;
}

.problems a {
    font-size: 16px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}

.recover {
    text-decoration: underline;
    color: white;
    background-color: rgba(255, 255, 255, 0);
}

.formMedia {
    grid-row-start: 3;
    grid-row-end: 4;
    align-self: end;
    position: relative;
    bottom: 30px;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.mediaItem {
    background-image: linear-gradient(35deg, var(--cor-azul-a), var(--cor-primary));
    padding: 12px;
    border-radius: 20px;
    transform: translateY(0px);
    transition: 0.4s ease;
}

.mediaItem:hover {
    transform: translateY(-15px);
}


@keyframes slideForm {
    0% {
        transform: translateX(100%);
        opacity: 0%;
    }

    100% {
        transform: translateX(0%);
        opacity: 100%;
    }
}

@keyframes slideLogo {
    0% {
        transform: translateX(-100%);
        opacity: 0%;
    }

    100% {
        transform: translateX(0%);
        opacity: 100%;
    }
}

@keyframes slideText {
    0% {
        transform: translateX(-100%);
        opacity: 0%;
    }

    100% {
        transform: translateX(0%);
        opacity: 100%;
    }
}

@keyframes slideRights {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@media screen and (max-width: 1600px) {
    .loginContentText h2 {
        font-size: 32px;
    }

    .loginContentText h3 {
        font-size: 28px;
    }
}

@media screen and (max-width: 1400px) {
    #login{
        background-size: cover;
    }
    .loginContentText{
        padding: 100px 0px 0px 50px;
    }
}

@media screen and (max-width: 1200px) {
    #login {
        grid-template-columns: auto 50%;
    }
    .square img{
        width: 80px;
    }
    .loginContentImg,
    .loginContentText,
    .rights,
    .form {
        animation: none !important;
        opacity: 100%;
    }
    .loginContentText{
        padding-right: 50px;
    }
}

@media screen and (max-width: 1000px){
    .formTitle, .formBody{
        width: 80%;
    }
    .rights p{
        padding: 0px 20px;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    #login {
        grid-template-columns: 100%;
        grid-template-rows: max-content;
    }

    .loginContentImg {
        backdrop-filter: blur(0px);
        z-index: 15;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .loginContentText {
        backdrop-filter: blur(0px);
        align-self: center;
        padding: 25px 0px 0px 50px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .loginContentText h2 {
        font-size: 28px;
    }

    .loginContentText h3 {
        font-size: 25px;
    }

    .form {
        height: 100%;
        width: 100%;
        backdrop-filter: blur(0px);
        grid-template-rows: auto;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
        padding: 50px 5% 40px 5%;
    }

    .formTitle h1 {
        font-size: 30px;
        padding-bottom: 20px;
    }

    .formTitle p {
        display: none;
    }

    .formBtn {
        margin-top: 20px;
    }

    .formMedia {
        position: initial;
        padding-top: 20px;
        align-self: center;
    }

    .rights {
        display: grid;
        justify-content: center;
        width: 100%;
        backdrop-filter: blur(0px);
        grid-row-start: 4;
        grid-row-end: 5;
        z-index: 15;
        margin: 0px;
        padding-bottom: 20px;
    }

    .rights p {
        margin: 0px;
    }

    .rights a {
        color: white;
    }

    .position {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .loginContentText{
        padding: 50px 50px 0px;
        height: fit-content;
    }
    .loginContentText h3 {
        font-size: 25px !important;
    }
    .square{
        display: none;
    }
    .rights{
        height: fit-content;
        align-self: end;
    }
    .form{
        padding: 50px;
        background: none;
    }
    .formItem input{
        background-color: #00215f00;
    }
    .formTitle h1 {
        font-size: 28px;
    }
    .formTitle, .formBody{
        width: 100%;
    }
    .mediaItem{
        padding: 10px;
    }
    .mediaItem img{
        width: 25px;
    }
}

@media screen and (max-width: 425px) {
    .loginContentImg, .loginContentText, .form{
        padding-left: 20px;
        padding-right: 20px;
    }
    .loginContentText h3 {
        font-size: 22px !important;
    }
    .problems{
        display: grid;
        justify-content: center;
    }
    .formTitle h1 {
        font-size: 25px;
    }
}