.divStuffingContent {
  display: flex;
  gap: 15px;
  align-items: center;
}

.divStuffingContent .inputs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.divStuffingContent .buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.extraCostsLIContainer>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collectionOrderLI>div button {
  margin-bottom: 1rem;
}

.extraCostsLIContainer>div p {
  color: #701010;
  white-space: initial;
  word-break: keep-all;
}

.extraCostsLIContainer>p {
  white-space: initial;
}

.nav-link {
  transition: .5s ease;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.664);
  font-weight: 600;
}

.nav-tabs .nav-link.active {
  font-weight: 700;
  color: #00215F !important;
}

.tab-content {
  padding: 10px 10px 50px;
  border: 1px solid #d8dbe0;
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 2px 10px 2px #0000002d;
}

.titulo-filtro {
  font-weight: 600;
}

.cont-filtro {
  margin-top: 20px;
  margin-bottom: 0px;
  border-radius: 10px;
  padding: 20px 20px;
  width: 100%;
  box-shadow: 0px 2px 10px 2px #0000002d;
}

.container-lg {
  padding: 2px;
  max-width: 97%;
}

button img {
  margin-left: 5px;
  margin-bottom: 2px;
}

@media (max-width: 1051px) {
  .container-lg {
    margin-top: 80px;
  }
}

@media (max-width: 600px) {
  .container-lg {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1700px){
  .container-lg{
    margin: 0px;
    justify-self: center;
  }
}


@media (max-width: 768px) {
  .divStuffingContent {
    flex-direction: column;
    align-items: initial;
  }

  .divStuffingContent .buttons {
    flex-direction: row;
  }

  .divStuffingContent>.inputs>div {
    flex-direction: column;
  }

  .collectionOrderLI p {
    color: #701010;
    white-space: initial;
    word-break: keep-all;
  }

  .collectionOrderLI>div {
    flex-direction: column;
  }

  .extraCostsLIContainer>div {
    flex-direction: column;
    align-items: flex-start;
  }

  .extraCostsLICost>div {
    flex-direction: column;
  }
}
